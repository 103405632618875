<template>
  <div>
    <div @contextmenu.prevent class="w">
      <div class="video_page">
        <div class="vedio_top">
          <div class="vedio_tit">
            <img
              @click="goBack"
              src="../../assets/img/video/icon_fanhui2.png"
              alt
            />
            <div>{{ vedioTitle }}</div>
          </div>
        </div>
        <div class="vedio_body">
          <div class="prism-player" id="player-con"></div>
          <!-- <video
            controls
            autoplay
            muted
            controlslist="nodownload"
            :src="play_url"
            id="media"
            :disablePictureInPicture="true"
            :style="{ width: vedioWidth2 }"
          ></video> -->
          <div class="vedio_rt" :style="{ width: vedioWidth }">
            <!-- 收起按钮 -->
            <div v-if="widthBtn" @click="changeVedioWidth(0)" class="toWidth">
              <img
                style="transform: rotate(180deg)"
                src="../../assets/img/video/left.png"
                alt
              />
              <!-- <img src="../../assets/img/video/icon_shouqi.png" alt /> -->
            </div>
            <!-- 展开按钮 -->
            <div v-else @click="changeVedioWidth(1)" class="toWidth toWidth2">
              <img src="../../assets/img/video/icon_shouqi.png" alt />
            </div>
            <!-- tabs切换 -->
            <div class="vedio_tabs">
              <div
                @click="changeTab(index)"
                :class="{ tabActive: tabActive == index }"
                class="tabsList"
                v-for="(item, index) in tabsList"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
            <!-- 目录列表 -->
            <div v-if="tabActive == 0" class="kechengList">
              <el-tree
                ref="treeBox"
                :default-expand-all="true"
                :data="menuList"
                :props="{ label: 'subsection', children: 'section' }"
                @node-click="changeVedio"
                :highlight-current="true"
                :expand-on-click-node="false"
                node-key="id"
              >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span>{{ node.label }}</span>
                  <!-- <span>{{ data.section }}</span> -->
                  <span v-if="!data.section">
                    <!-- <span v-if="is_buy == 0"> -->
                    <img
                      v-if="is_buy == 0 && data.is_free == 0"
                      src="../../assets/img/live/icon_2_.png"
                      alt
                    />
                    <!-- </span> -->
                  </span>
                  <!-- <span v-else>
                    <img src="../../assets/img/live/icon_2_.png" alt />
                  </span> -->
                </span>
              </el-tree>
            </div>
            <!-- 笔记 -->
            <div v-if="tabActive == 1">
              <div class="biji_box">
                <div class="biji_top">
                  <textarea
                    name
                    id
                    placeholder="请输入笔记内容"
                    v-model="noteContent"
                  ></textarea>
                  <div v-if="widthBtn" class="biji_btn" @click="addNote">
                    提交
                  </div>
                </div>
                <div class="biji_list">
                  <div class="biji_list_top">
                    <div class="list_title">笔记</div>
                  </div>
                  <div class="none" v-if="noteList.length == 0">暂无笔记</div>
                  <div v-else class="biji_list_box2">
                    <div
                      class="biji_list_body"
                      v-for="(item, index) in noteList"
                      :key="index"
                    >
                      <div class="biji_content">
                        {{ item.contents }}
                      </div>
                      <div class="biji_btm">
                        <div class="btm_left">
                          {{ item.create_at }}
                        </div>
                        <div class="btm_right">
                          <el-popconfirm
                            title="确定删除该笔记？"
                            @onConfirm="delNote(item.id)"
                          >
                            <img
                              slot="reference"
                              src="../../assets/img/course/cion_shanchu1.png"
                              alt=""
                            />
                          </el-popconfirm>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  videoDetail,
  videoUrl,
  vedioFinish,
  videoWatch,
  NoteList,
  addNote,
  delNote,
} from "../../assets/js/course.js";
export default {
  data() {
    return {
      player: {},
      old_id: 0,
      vedioTitle: "",
      id: "",
      play_url: "",
      // 视频右侧宽度
      vedioWidth: "27%",
      vedioWidth2: "73%",
      // vedioWidth2: "900px",
      widthBtn: true,
      pinglunNum: 3,
      edit: -1,
      del: -1,
      pre: false,
      next: false,
      bijiNum: 6,
      tabActive: 0,
      tabsList: ["目录", "笔记"],
      jie_active: 0,
      shiting_active: -1,
      jie_active2: -1,
      shiting_active2: -1,
      menuList: [],
      piangjiaList: [1, 2, 3, 4, 5, 6],
      c_id: "",
      c_s_id: "",
      // 笔记列表
      noteList: [],
      // 答疑列表
      dayiList: [],
      // 笔记内容
      noteContent: "",
      // 答疑内容
      dayiContent: "",
      video_id: "",
      culum_id: "",
      user_id: "",
      // 当前播放进度
      now_time: "",
      video_time: "",
      playTime: "",
    };
  },
  mounted() {
    this.old_id = this.$route.query.video_id;
    this.culum_id = this.$route.query.culum_id;
    this.video_id = this.$route.query.video_id;
    this.user_id = JSON.parse(window.localStorage.getItem("userInfo"))
      ? JSON.parse(window.localStorage.getItem("userInfo")).id
      : "";
    this.getVedioList();

    // 监听视频播放结束
    // let myVideo = document.getElementById("media");
    // let that = this;
    // myVideo.addEventListener("ended", function () {
    //   vedioFinish({
    //     user_id: that.user_id,
    //     culum_id: that.culum_id,
    //     video_id: that.video_id,
    //   }).then((res) => {
    //     // console.log(res);
    //     if (res.code == 200) {
    //       that.$message.success("该视频已学完！");
    //       return;
    //     }
    //   });
    // });
    // 监听当前播放时间
    // myVideo.addEventListener("timeupdate", function () {
    //   that.now_time = myVideo.currentTime;
    // });
  },
  destroyed() {
    this.watchTime();
    let timer = setInterval(() => {
      clearInterval(timer);
      this.player.dispose();
      // console.log(22222);
    }, 1000);
  },
  created() {
    // 屏蔽f12
    window.onkeydown = window.onkeyup = window.onkeypress = function (event) {
      // 判断是否按下F12，F12键码为123
      if (event.keyCode == 123) {
        event.preventDefault(); // 阻止默认事件行为
        window.event.returnValue = false;
      }
    };
  },
  methods: {
    // 初始化播放器
    init() {
      // console.log(this.play_url)
      this.player = new Aliplayer(
        {
          id: "player-con",
          source: this.play_url,
          width: "100%",
          height: "100%",
          autoplay: true,
          isLive: false,
          rePlay: false,
          videoHeight: "100%",
          playsinline: true,
          preload: true,
          autoPlayDelayDisplayText: "加载中...",
          language: "zh-cn",
          controlBarVisibility: "hover",
          useH5Prism: true,
          skinLayout: [
            {
              name: "bigPlayButton",
              align: "blabs",
              x: 30,
              y: 80,
            },
            {
              name: "H5Loading",
              align: "cc",
            },
            {
              name: "errorDisplay",
              align: "tlabs",
              x: 0,
              y: 0,
            },
            {
              name: "infoDisplay",
            },
            {
              name: "tooltip",
              align: "blabs",
              x: 0,
              y: 56,
            },
            {
              name: "controlBar",
              align: "blabs",
              x: 0,
              y: 0,
              children: [
                {
                  name: "progress",
                  align: "blabs",
                  x: 0,
                  y: 44,
                },
                {
                  name: "playButton",
                  align: "tl",
                  x: 15,
                  y: 12,
                },
                {
                  name: "timeDisplay",
                  align: "tl",
                  x: 10,
                  y: 7,
                },
                {
                  name: "fullScreenButton",
                  align: "tr",
                  x: 10,
                  y: 12,
                },
                {
                  name: "setting",
                  align: "tr",
                  x: 15,
                  y: 12,
                },
                {
                  name: "volume",
                  align: "tr",
                  x: 5,
                  y: 10,
                },
              ],
            },
          ],
        }
        // function (player) {
        //   console.log("播放器创建成功");
        // }
      );
      let that = this;
      this.player.on("ready", function (e) {
        // console.log(that.video_time);
        // 指定播放时间
        that.player.seek(that.video_time);
      });
      //  监听视频播放结束
      this.player.on("ended", () => {
        vedioFinish({
          user_id: that.user_id,
          culum_id: that.culum_id,
          video_id: that.video_id,
        }).then((res) => {
          // console.log(res);
          if (res.code == 200) {
            // that.$message.success("该视频已学完！");
            // return;
            let arr = [];
            let i = "";
            // console.log(that.video_id);
            this.menuList.map((x) => {
              x.section.map((y) => {
                arr.push(y);
                if (y.id == that.video_id) {
                  // console.log(arr.indexOf(y));
                  i = arr.indexOf(y);
                }
              });
            });
            // console.log(arr[i + 1].id);
            this.video_id = arr[i + 1].id;
            this.old_id = arr[i + 1].id;
            // 自动播放下一集
            videoUrl({
              culum_id: this.culum_id,
              video_id: this.video_id,
              user_id: this.user_id,
            }).then((res) => {
              // console.log(res);
              let newUrl = res.data.v_url;
              this.player.loadByUrl(newUrl);
              this.video_time = res.data.setime;
              // 目录默认选中
              this.$nextTick(() => {
                // treeBox 元素的ref   value 绑定的node-key
                this.$refs.treeBox.setCurrentKey(arr[i + 1].id);
              });
            });
          }
        });
      });
      // 监听当前播放时间
      this.player.on("timeupdate", () => {
        this.getCurrentTime();
      });
    },
    getCurrentTime() {
      this.now_time = this.player.getCurrentTime();
    },

    // 记录当前播放进度
    watchTime() {
      videoWatch({
        user_id: this.user_id,
        video_id: this.old_id,
        setime: this.now_time,
        culum_id: this.culum_id,
      }).then((res) => {
        // console.log(res);
      });
    },
    // 获取视频地址
    getVideoUrl(video_id, type) {
      videoUrl({
        culum_id: this.culum_id,
        video_id: video_id,
        user_id: this.user_id,
      }).then((res1) => {
        // console.log(res1);
        if (res1.code == 200) {
          this.play_url = res1.data.v_url;
          this.video_time = res1.data.setime;
          this.init();
          this.old_id = video_id;
          // if (type && type == 2) {
          //   this.$message.success("视频切换成功");
          // }
          // let myVideo = document.getElementById("media");
          // myVideo.load();
          // myVideo.currentTime = res1.data.setime;
          // console.log(myVideo.currentTime);
        }
      });
    },
    // 获取视频信息
    getVedioList() {
      videoDetail({
        culum_id: this.culum_id,
        user_id: this.user_id,
        video_id: this.video_id,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.vedioTitle = res.data.culum.alias_name;
          this.is_buy = res.data.culum.is_buy;
          this.menuList = res.data.section;
          // console.log(this.menuList[0].section[0].id);
          // 上一页跳转过来时选中的id
          this.$nextTick(() => {
            // treeBox 元素的ref   value 绑定的node-key
            this.$refs.treeBox.setCurrentKey(this.video_id);
          });
          this.getVideoUrl(res.data.video_id);
        }
      });
    },
    // 切换视频
    changeVedio(data, Node) {
      if (Node.childNodes.length > 0) {
        return;
      }
      this.video_id = data.id;
      this.watchTime();
      // 先销毁之前的播放器 以免样式重叠
      this.player.dispose();
      this.getVideoUrl(data.id, 2);
    },
    // 添加笔记
    addNote() {
      if (!this.noteContent.trim()) {
        this.$message.error("请输入笔记内容！");
        return;
      }
      addNote({
        user_id: this.user_id,
        culum_id: this.culum_id,
        contents: this.noteContent,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$message.success("笔记添加成功");
          this.noteContent = "";
          this.getNote();
        }
      });
    },
    // 获取笔记列表
    getNote() {
      NoteList({
        user_id: this.user_id,
        culum_id: this.culum_id,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.noteList = res.data;
        }
      });
    },
    // 删除笔记
    delNote(id) {
      delNote({
        id: id,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.getNote();
        }
      });
    },
    // 展开与收起
    changeVedioWidth(i) {
      let myVideo = document.getElementById("media");
      if (i == 0) {
        this.vedioWidth = "1px";
        this.vedioWidth2 = "100%";
        // myVideo.width = 1000;
        this.widthBtn = !this.widthBtn;
      } else {
        this.vedioWidth = "27%";
        this.vedioWidth2 = "73%";
        // myVideo.width = 800;
        this.widthBtn = !this.widthBtn;
      }
    },
    changeTab(i) {
      this.tabActive = i;
      if (i == 2) {
        // this.getVedioBiji();
      } else {
        if (i == 1) {
          this.getNote();
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 20px;
}
/deep/ .prism-controlbar {
  bottom: 10px!important;
}
/deep/ .kechengList .el-tree {
  background-color: transparent;
  color: #999;
}
/deep/ .kechengList .el-tree-node__content {
  position: relative;
  height: 60px;
  font-size: 14px;
  border-bottom: 1px solid #555555;
}
/deep/ .kechengList .el-tree-node__content:hover {
  background-color: #121212;
  color: #0c6ae6 !important;
}
/deep/ .kechengList .el-tree-node:focus > .el-tree-node__content {
  background-color: #121212;
  color: #0c6ae6 !important;
}
/deep/
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #121212;
  color: #0c6ae6 !important;
}
// 隐藏滚动条
.pingjia_body_list::-webkit-scrollbar,
.kechengList::-webkit-scrollbar,
.biji_list_box2::-webkit-scrollbar,
.dayi_box::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.pingjia_body_list,
.kechengList,
.biji_list_box2,
.dayi_box {
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
  // overflow-x: hidden;
  overflow-y: auto;
}

// 修改placeholder样式
@deep: ~">>>";

input::-webkit-input-placeholder {
  color: #999;
  font-size: 14px;
}

@deep: ~">>>";

input::-moz-input-placeholder {
  color: #999;
  font-size: 14px;
}

@deep: ~">>>";

input::-ms-input-placeholder {
  color: #999;
  font-size: 14px;
}

.kechengCurrent {
  color: #0c6ae6 !important;
}

.tabCurrent {
  color: #0c6ae6;
}
.jie_active {
  color: #0c6ae6 !important;
  background-color: #121212;
}
.jie_active2 {
  color: #0c6ae6 !important;
}

.shiting_active {
  color: #fff !important;
  background-color: #f13232;
}

.jw-icon-barlogo-new {
  display: none !important;
}

.jw-skin-bce .jw-button-color,
.jw-skin-bce .jw-text {
  display: none !important;
}

.tabActive {
  background-color: #333333;
  //   height: 48px;
  border-top: 2px solid #525252;
}

.w {
  // width: 1200px;
  // margin: 0 auto;
  .video_page {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 6;
  }

  .vedio_top {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #444;
    padding: 0 40px;
    box-sizing: border-box;
  }

  .vedio_tit {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #fff;
    // font-weight: 700;
    img {
      width: 20px;
      height: 20px;
      margin-right: 40px;
      cursor: pointer;
    }
  }
  .vedio_body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    // height: 880px;
    height: 92%;
    background-color: #333333;
    padding: 20px 0;
    padding-top: 0;
    box-sizing: border-box;
    // video {
    //   width: 75%;
    // }
    // padding-left: 50px;
    #playercontainer {
      // width: 800px;
      .jw-icon-barlogo-new {
        display: none !important;
      }
    }

    .vedio_rt {
      position: relative;
      transition: all linear 0.1s;
      // width: 516px;
      border-left: 2px solid rgba(0, 0, 0, 0.1);

      .toWidth {
        width: 32px;
        height: 40px;
        background: rgba(0, 0, 0, 1);
        opacity: 0.2;
        border-radius: 0px 20px 20px 0px;
        // text-align: center;
        line-height: 40px;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translate(-50%);
        z-index: 33;
        cursor: pointer;

        img {
          width: 10px;
          height: 18px;
          margin-left: 8px;
          margin-top: 10px;
        }
      }

      .toWidth2 {
        top: 45%;
        left: -33px;
        transform: rotate(180deg);
        background-color: #525252;
      }

      //   color: #fff;
      .vedio_tabs {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        background-color: #525252;
        color: #fff;

        .tabsList {
          width: 172px;
          height: 48px;
          text-align: center;
          font-size: 20px;
          color: #fff;
          cursor: pointer;
          line-height: 48px;
        }
      }

      .kechengList {
        // height: 790px;
        height: calc(100vh - 130px);
      }
      .dayi_box {
        width: 100%;
        // height: 790px;
        padding: 30px;
        box-sizing: border-box;
        color: #999;
        font-size: 14px;
        overflow-y: auto;
        .dayi_list {
          padding: 10px 0;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
        .dayi_content {
          padding: 10px 0;
          box-sizing: border-box;
        }

        .dayi_btm {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px;
          .pinglun {
            display: flex;
            align-items: center;
            img {
              width: 18px;
              height: 15px;
              margin-right: 8px;
            }
          }
        }
      }
      textarea {
        width: 100%;
        background-color: #272727;
        color: #999;
        outline: none;
        resize: none;
        // width: 454px;
        height: 110px;
        border-radius: 6px;
        padding: 10px;
        box-sizing: border-box;
      }
      .biji_btn {
        position: absolute;
        right: 0;
        bottom: -50px;
        width: 80px;
        height: 40px;
        background: rgba(12, 106, 231, 1);
        border-radius: 20px;
        font-size: 14px;
        color: #fff;
        text-align: center;
        line-height: 40px;
        margin-top: 10px;
        // margin-left: 375px;
        cursor: pointer;
      }
      .biji_btn2 {
        height: 30px;
        line-height: 30px;
        margin-left: 0;
      }
      .tips {
        font-size: 8px;
        color: #fff;
        height: 20px;
        padding: 0 5px;
        box-sizing: border-box;
        background: rgba(101, 101, 101, 1);
        border: 1px solid rgba(204, 204, 204, 1);
        border-radius: 4px;
        text-align: center;
      }

      .tips1 {
        position: absolute;
        left: 0;
        bottom: -25px;
        // right: 5px;
        // top: 5px;
      }

      .biji_top {
        position: relative;
        // width: 454px;
        margin: 0 auto;
      }

      .biji_box {
        padding: 30px;
        box-sizing: border-box;
        // .biji_list::-webkit-scrollbar {
        //   display: none; /* Chrome Safari */
        // }

        // .biji_list {
        //   scrollbar-width: none; /* firefox */
        //   -ms-overflow-style: none; /* IE 10+ */
        //   overflow-x: hidden;
        //   overflow-y: auto;
        // }
        .biji_list {
          // width: 454px;
          // height: 570px;
          margin-top: 50px;
          color: #999;
          font-size: 14px;

          .none {
            font-size: 18px;
            text-align: center;
            margin-top: 100px;
          }
          .biji_list_top {
            color: #999;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 30px 0;

            .rrrow {
              width: 50px;
              position: relative;
              text-align: center;

              img {
                width: 9px;
                height: 12px;
                margin: 0 5px;
                cursor: pointer;
              }

              img:nth-child(2) {
                transform: rotate(180deg);
              }
            }
          }
          .biji_list_box2 {
            width: 100%;
            // height: 500px;
            height: calc(100vh - 400px);
            overflow-y: auto;
          }
          .biji_list_body {
            width: 100%;
            margin-bottom: 30px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            // opacity: 0.1;
            .biji_content,
            textarea {
              width: 100%;
              padding: 10px;
              box-sizing: border-box;
              background-color: #272727;
              border-radius: 6px;
              color: #999;
              margin-bottom: 15px;
              resize: none;
              outline: none;
            }

            .biji_btm {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;

              .btm_left {
                font-size: 12px;
                color: #999;
              }

              .btm_right {
                text-align: right;
                width: 80px;
                position: relative;

                img {
                  width: 12px;
                  height: 16px;
                  cursor: pointer;
                }
              }
            }
          }
          .biji_list_body:last-child {
            border: none;
          }
        }
      }

      .list_title {
        margin-bottom: 20px;
      }

      .pingjia_box {
        padding: 30px;
        box-sizing: border-box;

        .biji_body {
          font-size: 14px;
          color: #999;

          .pingjia_body_list {
            height: 550px;
          }

          .pinglun_list {
            display: flex;
            padding: 30px 0;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            .linglun_list_left {
              img {
                width: 31px;
                height: 31px;
                border-radius: 50%;
                margin-right: 10px;
              }
            }

            .pinglun_list_right {
              width: 400px;

              .pinglun_desc {
                font-size: 16px;
                margin: 30px 0;
              }

              .pinglun_time {
                font-size: 12px;
              }
            }
          }

          .none {
            text-align: center;
            margin-top: 100px;

            img {
              width: 40px;
              height: 40px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
